<template>
  <div class="key-benefit__wrapper">
    <div
      class="key-benefit col-11 col-sm-10 m-auto"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-offset="300"
    >
      <div class="key-benefit__content">
        {{ keyBenefit.title }}
      </div>
      <div class="key-benefit__container">
        <div class="" v-for="(item, indx) in job" :key="indx">
          <div class="key-benefit__itemCenter">
            <div class="key-benefit__item">
              <img v-lazy="item.icon" alt="keyBenefit" />
              <h3>{{ item.title }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Key-benefit',
  data() {
    return {
      keyBenefitImg: '/images/recruitment/img-benefit.png',
      keyBenefit: this.$t('recruiment').keyBenefit,
      job: [
        {
          title: this.$t('recruiment').keyBenefit.cards.salary.title,
          sub: this.$t('recruiment').keyBenefit.cards.salary.sub,
          colorBg: '#fff4ed',
          icon: '/images/icons/recruitment/salary.svg'
        },
        {
          title: this.$t('recruiment').keyBenefit.cards.hours.title,
          sub: this.$t('recruiment').keyBenefit.cards.hours.sub,
          colorBg: '#E9F5FF',
          icon: '/images/icons/recruitment/hours.svg'
        },
        {
          title: this.$t('recruiment').keyBenefit.cards.internal.title,
          sub: this.$t('recruiment').keyBenefit.cards.internal.sub,
          colorBg: '#E9F5FF',
          icon: '/images/icons/recruitment/training.svg'
        },
        {
          title: this.$t('recruiment').keyBenefit.cards.activity.title,
          sub: this.$t('recruiment').keyBenefit.cards.activity.sub,
          colorBg: ' #fff4ed',
          icon: '/images/icons/recruitment/party.svg'
        },
        {
          title: this.$t('recruiment').keyBenefit.cards.workhome.title,
          sub: this.$t('recruiment').keyBenefit.cards.workhome.sub,
          colorBg: '#fff4ed',
          icon: '/images/icons/recruitment/remote.svg'
        },
        {
          title: this.$t('recruiment').keyBenefit.cards.environment.title,
          sub: this.$t('recruiment').keyBenefit.cards.environment.sub,
          colorBg: ' #E9F5FF',
          icon: '/images/icons/recruitment/workplace.svg'
        }
      ]
    };
  },
  watch: {
    lang() {
      this.keyBenefit = this.$t('recruiment').keyBenefit;
      this.job = [
        {
          title: this.$t('recruiment').keyBenefit.cards.salary.title,
          sub: this.$t('recruiment').keyBenefit.cards.salary.sub,
          colorBg: '#fff4ed',
          icon: '/images/icons/recruitment/salary.svg'
        },
        {
          title: this.$t('recruiment').keyBenefit.cards.hours.title,
          sub: this.$t('recruiment').keyBenefit.cards.hours.sub,
          colorBg: '#E9F5FF',
          icon: '/images/icons/recruitment/hours.svg'
        },
        {
          title: this.$t('recruiment').keyBenefit.cards.internal.title,
          sub: this.$t('recruiment').keyBenefit.cards.internal.sub,
          colorBg: '#E9F5FF',
          icon: '/images/icons/recruitment/training.svg'
        },
        {
          title: this.$t('recruiment').keyBenefit.cards.activity.title,
          sub: this.$t('recruiment').keyBenefit.cards.activity.sub,
          colorBg: ' #fff4ed',
          icon: '/images/icons/recruitment/party.svg'
        },
        {
          title: this.$t('recruiment').keyBenefit.cards.workhome.title,
          sub: this.$t('recruiment').keyBenefit.cards.workhome.sub,
          colorBg: '#fff4ed',
          icon: '/images/icons/recruitment/remote.svg'
        },

        {
          title: this.$t('recruiment').keyBenefit.cards.environment.title,
          sub: this.$t('recruiment').keyBenefit.cards.environment.sub,
          colorBg: ' #E9F5FF',
          icon: '/images/icons/recruitment/workplace.svg'
        }
      ];
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  }
};
</script>

<style lang="scss" scroped>
img {
  object-fit: cover;
}
.key-benefit__wrapper {
  background: url('/images/bg/bg-bene.png') rgba(255, 216, 193, 1);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0;
}
.key-benefit {
  position: relative;
  margin: 0 auto;
  padding: 50px 0;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  &::after {
    content: '';
    position: absolute;
    background: linear-gradient(180deg, #ff6e26 0%, #ffe6d8 100%);
    transform: rotate(-30deg);
    width: 130px;
    height: 130px;
    border-radius: 50%;
    bottom: 360px;
    left: -120px;
    z-index: -1;
  }
}
.key-benefit__content {
  position: relative;
  font-weight: 700;
  font-size: 40px;
  color: var(--blue-text);
  width: 100%;
  text-align: center;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  &::after {
    content: '';
    position: absolute;
    background: url('/images/bg/circle-title.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 38px;
    height: 38px;
    z-index: -1;
    left: -15px;
    top: 0;
  }
}
.key-benefit__container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  max-width: 1000px;
  text-align: center;
  justify-content: center;
  gap: 20px;
}

.key-benefit__itemCenter {
  display: inline-block;
}

.key-benefit__item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  gap: 16px;
  height: 280px;
  width: 280px;
  padding: 40px;
  border-radius: 50%;
  text-align: center;
  margin-bottom: 20px;
  & > img {
    width: 120px;
    height: 120px;
  }
  h3 {
    font-weight: 700;
    font-size: 21px;
    color: var(--blue-dark);
    margin: 0;
  }
  p {
    font-weight: 400;
    font-size: 18px;
    color: var(--grey-text);
    margin: 0;
  }
}

@media (max-width: 1350px) {
  .key-benefit__content,
  .key-benefit__container {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .key-benefit__wrapper {
    margin-top: 0;
  }
  .key-benefit {
    flex-direction: column;
  }
  .key-benefit__content,
  .key-benefit__container {
    width: 100%;
  }
  .key-benefit__item {
    height: auto;
    min-height: 300px;
    width: 300px;
    margin: 0 auto;
  }
  .key-benefit__image {
    height: 300px;
    width: 300px;
  }
  .col-lg-4 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .key-benefit {
    gap: 20px;
    padding: 0;
  }
  .key-benefit__content {
    font-size: 32px;
    text-align: center;
  }
  .key-benefit__item {
    height: auto;
    min-height: 150px;
    width: 150px;
    margin: 0 auto;
    padding: 20px;
    img {
      width: 50px;
      height: 50px;
    }
    h3 {
      font-size: 16px;
    }
  }
}
</style>
